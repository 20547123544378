import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CardBody,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Col, Row, Typography, Card } from 'antd';
import styled from 'styled-components';
import { ButtonGroup } from 'react-bootstrap-table';

import 'react-datepicker/dist/react-datepicker.css';
import { ScrollIcon, ArrowBigUpDash, ArrowBigDownDash, CalendarArrowDown } from 'lucide-react';

import ComponentCard from '../../../components/ComponentCard';
import userService from '../../../services/UserService';
import coursesServices from '../../../services/CoursesService';
import eventService from '../../../services/EventService';
import calendarService from '../../../services/CalendarService';
import studentService from '../../../services/StudentService';
import { useTranslation } from '../../../hooks/useTranslation';
import participantsService from '../../../services/ParticipantsService';
import TinkerTable from '../../ui/TinkerTable';

const { Title } = Typography;

const StudentList = styled.div`
  padding: 8px;
  min-height: 300px;
  background-color: ${(props) => (props.isDraggingOver ? '#e6f7ff' : '#f0f2f5')};
`;
const StudentItem = styled.div`
  user-select: none;
  padding: 8px;
  margin-bottom: 3px;
  background-color: ${(props) => (props.isDragging ? '#fafafa' : 'white')};
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

const CalendarEntryButton = styled(Button)`
  margin: 5px;
  transition: all 0.3s ease;
  padding: 10px;
  border-color: #1890ff;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
  &.selected {
    background-color: #1890ff;
    color: white;
    border-color: #1890ff;
    box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
  }
`;

const CalendarEntryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-height: 110px;
  overflow: auto;
  border-left: 1px solid #d9d9d9;
`;

const MonthButton = styled(Button)`
  margin: 5px;
  transition: all 0.3s ease;
  width: 100%;
  border-color: #1895ff;
  box-shadow: 0 2px 4px rgba(24, 144, 255, 0.2);
  &.selected {
    background-color: #1890ff;
    color: white;
    border-color: #1890ff;
  }
`;

const Groups = () => {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const [courses, setCourses] = useState([]);
  const [masters, setMasters] = useState([]);
  const [lcf, setLcf] = useState([]);
  const [studentModal, setStudentModal] = useState(false);
  const [calendarEntries, setCalendarEntries] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [calendarRange, setCalendarRange] = useState({ start: null, end: null });
  const [selectedCalendarEntry, setSelectedCalendarEntry] = useState(null);
  const [eventStudents, setEventStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [softDeletedStudents, setSoftDeletedStudents] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [entriesForSelectedMonth, setEntriesForSelectedMonth] = useState([]);
  const [tempSelectedCalendarEntry, setTempSelectedCalendarEntry] = useState(null);

  const [allStudents, setAllStudents] = useState([]);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [unassignedStudents, setUnassignedStudents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('all');

  const [isLoading, setIsLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [dayInMonth, setDayInMonth] = useState([]);
  const monthsContainerRef = useRef(null);
  

  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState([]);
 

  const filteredUnassignedStudents = unassignedStudents.filter((student) =>
    `${student.name} ${student.lname}`.toLowerCase().includes(filterText.toLowerCase()),
  );
  const [formData, setFormData] = useState({
    name: '',
    start: null,
    end: null,
    timestart: null,
    timeend: null,
    type: 'group',
    course: '',
    teacher: '',
    master: '',
    lcf: '',
    active: true,
  });

  const toggleModal = () => {
    setModal(!modal);
    if (!modal) {
      setFormData({
        name: '',
        start: null,
        end: null,
        timestart: null,
        timeend: null,
        type: 'group',
        course: '',
        teacher: '',
        master: '',
        lcf: '',
        active: true,
      });
    }
  };
  
  const getUniqueMonths = (data = []) => {
    const months = (data.length>0 ? data :calendarEntries).map((entry) => moment(entry.start).format('YYYY-MM'));
    setUniqueMonths([...new Set(months)]);
  };
  const toggleStudentModal = () => {
    if (studentModal) {
      // Reset all state when closing the modal
      setSelectedCalendarEntry(null);
      setAssignedStudents([]);
      setUnassignedStudents([]);
      setSoftDeletedStudents([]);
      setSelectedEvent(null);
      setEntriesForSelectedMonth([]);
      setSelectedMonth(null);
      setTempSelectedCalendarEntry(null);
      setFilterText('');
      setCalendarEntries([]);
      setUniqueMonths([]);
      setEventStudents([]);
    } else {
      // When opening the modal
      getUniqueMonths();
      setUpdateModal(true);
    }
    
    setStudentModal(!studentModal);
  };

  const getLcf = async () => {
    try {
      const response = await userService.getByRole('lcf');
      setLcf(response);
    } catch (error) {
      console.error('Error fetching lcf:', error);
    }
  };

  const getMasters = async () => {
    try {
      const response = await userService.getByRole('master');
      setMasters(response);
    } catch (error) {
      console.error('Error fetching masters:', error);
    }
  };

  const getEvents = async (state) => {
    
    setIsLoading(true);
 
    try {
      const response = await eventService.getAll({
      });
      
      if (response && response.data) {
        setEvents(response.data);
        
        setFilteredData(response.data);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (state, instance) => {
    getEvents(state);
  };
  
  const filterByYear = async(year) => {
    setSelectedYear(year);
    if (year === 'all') {
      getEvents();
    } else {
      const filtered = events.filter((event) => moment(event.start).format('YYYY') === year);
      setFilteredData(filtered);
    }

    
  };
  const getTeachers = async () => {
    try {
      const response = await userService.getByRole('teacher');
      setTeachers(response);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const getCourses = async () => {
    try {
      const response = await coursesServices.getAll();
      setCourses(response);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const getStudents = async () => {
    try {
      const students1 = await studentService.getAll();
      setAllStudents([]);
      setAllStudents(students1);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  useEffect(() => {
    setDayInMonth(moment().daysInMonth());
    
    getMasters();
    getLcf();
    getTeachers();
    getCourses();
    getStudents();
    //getEvents(0, pagination.pageSize, selectedYear);
    getEvents();
  }, []);

 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: date,
    }));

    if (name === 'start') {
      const suggestedEndDate = new Date(date);
      suggestedEndDate.setMonth(5);
      suggestedEndDate.setDate(30);
      suggestedEndDate.setFullYear(suggestedEndDate.getFullYear() + 1);

      suggestedEndDate.setDate(suggestedEndDate.getDate());
      setFormData((prevState) => ({
        ...prevState,
        end: suggestedEndDate,
      }));
    } else if (name === 'timestart') {
      const suggestedEndTime = new Date(date);
      suggestedEndTime.setMinutes(suggestedEndTime.getMinutes() + 45);
      setFormData((prevState) => ({
        ...prevState,
        timeend: suggestedEndTime,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const eventData = {
      ...formData,
      start: moment(formData.start).format('YYYY-MM-DD'),
      end: moment(formData.end).format('YYYY-MM-DD'),
      timestart: moment(formData.timestart).format('HH:mm'),
      timeend: moment(formData.timeend).format('HH:mm'),
      master: formData.master.toString(),
      lcf: formData.lcf.toString(),
      course: formData.course.toString(),
      teacher: formData.teacher.toString(),
    };

    try {
      if (formData._id) {
        await eventService.update(eventData._id, eventData);
      } else {
        await eventService.create(eventData);
      }
      getEvents();
      toggleModal();
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handleEdit = (event) => {
    let timestart = ""
    let timeend = ""
    if (typeof event.timestart === 'string' && !event.timestart.includes('T')) {
       timestart = new Date(`1970-01-01T${event.timestart}`)
    }else{
      timestart = new Date(`1970-01-01T${moment(event.timestart, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}`)
    } 

    if (typeof event.timeend === 'string' && !event.timeend.includes('T')) {
      timeend = new Date(`1970-01-01T${event.timeend}`)
    }else{
      timeend = new Date(`1970-01-01T${moment(event.timeend, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}`)
    }

    

    setFormData({
      _id: event._id,
      name: event.name,
      start: new Date(event.start),
      end: new Date(event.end),
      timestart: timestart,
      timeend: timeend,
      type: event.type,
      course: event.course,
      teacher: event.teacher,
      master: event.master,
      lcf: event.lcf,
      active: event.active,
    });
    setModal(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this event?'))) {
      try {
        await eventService.delete(id);
        getEvents();
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };
   

  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };
  const refreshData = async (event) => {
    try {
      const allParticipants = await participantsService.getAllByUser(event.lcf)
      if(allParticipants){
         setEventStudents(allParticipants);
        setUnassignedStudents(students.filter((student) => !allParticipants.some((p) => p.student_id === student._id)));
      }
      const entries = await calendarService.getCalendarEntriesForEvent(event._id).then
      ((response) => {
        
        setCalendarEntries(response.data);
        getUniqueMonths(response.data);
        return response;
      });
      setCalendarEntries(entries.data) 
      
      setSelectedCalendarEntry(null);
      setAssignedStudents([]);
      
      const softDeleted = await calendarService.getSoftDeletedStudentsForEvent(event._id).then((response) => {
        setUpdateModal(false);
        return response;
      });

      setSoftDeletedStudents(softDeleted.data);
      setIsLoading(false);
      setUpdateModal(false);

     } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleManageStudents = async (event) => {
    
    setSelectedEvent(event);
    try {
      toggleStudentModal();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (selectedEvent) {
      refreshData(selectedEvent);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (students.length > 0) {
      refreshData(selectedEvent);
    }
  }, [students]);
  

  const handleCalendarEntrySelect = async (entry) => {
    console.log('entry:', entry);
    setSelectedCalendarEntry(entry._id);
    setTempSelectedCalendarEntry(entry);  
    const entryId = entry._id;  
    try {
       await calendarService.getStudentsForCalendarEntry(entryId).then((response) => {
        setAssignedStudents(response || []);
        return response;
      });

      //if last_event_name is null or if is not exist
    
      const astud =  allStudents.filter(student => student.last_event_name === null || student.last_event_name === undefined);
        
       setUnassignedStudents(astud);
    } catch (error) {
      console.error('Error fetching students for calendar entry:', error);
    }
  };

  const handleAddStudentToSubsequentEntries = async (calendarEntryId, studentId) => {
    setIsLoading(true);
    setUpdateModal(true);
    try {
     // await refreshData(selectedEvent);
      await calendarService.addStudentToSubsequentEntries(calendarEntryId, studentId).then((response) => {
        getStudents().then(() => {
          refreshData(selectedEvent);
        });
        return response;
      });
    } catch (error) {
      console.error('Error adding student to subsequent entries:', error);
    } 
  };

  const handleRemoveStudentFromSubsequentEntries = async (calendarEntryId, studentId) => {
    setIsLoading(true);
    setUpdateModal(true);
    try {
     // await refreshData(selectedEvent);
      await calendarService.removeStudentFromSubsequentEntries(calendarEntryId, studentId).then((response) => {
        getStudents().then(() => {
          refreshData(selectedEvent);
        });
        return response;
      });
       //setUnassignedStudents((prev) => [...prev, allStudents.find((student) => student._id === studentId)]);
    } catch (error) {
      console.error('Error removing student from subsequent entries:', error);
    } 
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === 'assigned' ? assignedStudents : unassignedStudents,
        source.index,
        destination.index,
      );

      if (source.droppableId === 'assigned') {
        setAssignedStudents(items || []);
      } else {
        setUnassignedStudents(items);
      }
    } else {
      const sourceList = source.droppableId === 'assigned' ? assignedStudents : unassignedStudents;
      const destList =
        destination.droppableId === 'assigned' ? assignedStudents : unassignedStudents;

      if (destination.droppableId === 'assigned' && destList.length >= 10) {
        return;
      }

      const resultt = move(sourceList, destList, source, destination);

      setAssignedStudents(resultt.assigned || []);
      setUnassignedStudents(resultt.unassigned || []);

      const studentId = sourceList[source.index]._id;
      if (destination.droppableId === 'assigned') {
        await calendarService.addStudentToCalendarEntry(selectedCalendarEntry, studentId);
      } else {
        await calendarService.removeStudentFromCalendarEntry(selectedCalendarEntry, studentId);
      }

     }
  };


 

  const getEntriesForSelectedMonth = () => {
      const entries = calendarEntries.filter((entry) => moment(entry.start).format('YYYY-MM') === selectedMonth);
            //if date now is less than 7th day of the month, then previus month is also included in active entries if not only current month is active
      entries.forEach((entry) => {
        const start = new Date(entry.start);
         const now = new Date();
         const firstDayOfPMonth = new Date(now.getFullYear(), now.getMonth()-1, 1);
         entry.active = false;
        if (now.getDate() < 7) {
         if(start >= firstDayOfPMonth){
           entry.active = true;
         }else{
           entry.active = false;
         }
        }else{
          //eslint-disable-next-line
          if(start >= new Date(now.getFullYear(), now.getMonth(), 1)){
            entry.active = true;
          }else{
            entry.active = false;
          }

        }
      }
      );
        
        setEntriesForSelectedMonth(entries); 

 };
  useEffect(() => {
    if (selectedMonth) {
      getEntriesForSelectedMonth();
    }
  }, [selectedMonth]);
  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    console.log('month:', month);
    setSelectedCalendarEntry(null);
  };
  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        }  
            return String(content).toLowerCase().includes(filter.value.toLowerCase());
        
    }

    return true;
};
  useEffect(() => {
    if (monthsContainerRef.current && uniqueMonths.length > 0) {
      const currentMonth = moment().format('YYYY-MM');
      const currentMonthButton = monthsContainerRef.current.querySelector(`[data-month="${currentMonth}"]`);
      
      if (currentMonthButton) {
        currentMonthButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (!selectedMonth) {
          handleMonthSelect(currentMonth);
        }
      }
    }
  }, [uniqueMonths]);

  const getColumns = () => [
    {
      Header: t('Name'),
      id: 'name',
      accessor: 'name',
      filterable: true,
      sortable: true,
      Cell: (row) => {
        const days = [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')];
        const date = new Date(row.original.start);
        return (
          <>
            <i className={`bi bi-record-fill fs-4 text-${row.original.active ? t('success') : t('warning')}`}></i>
            {`${row.value} (${days[date.getDay()]})`}
          </>
        );
      }
    },
    {
      Header: t('Time'),
      id: 'timestart',
      accessor: (row) => {
        if (typeof row.timestart === 'string' && !row.timestart.includes('T')) {
          return `${row.timestart} - ${row.timeend}`;
        }
        return `${moment(row.timestart, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm')}-${moment(
          row.timeend,
          'YYYY-MM-DDTHH:mm:ss',
        ).format('HH:mm')}`;
      },
      filterable: true,
      sortable: true,
    },
    {
      Header:t("LCF"),
      id: 'lcf',
      accessor: (row) => {
        const lcfa = lcf.find((l) => l._id === row.lcf);
        return lcfa ? `${lcfa.email}` : t('N/A');
      },
      filterable: true,
      show: user.role !== 'lcf',

    },
    {
      Header: t('Students'),
      id: 'numberOfParticipations',
      accessor: 'numberOfParticipations',
      filterable: false,
      sortable: true,
    },
    {
      Header: t('Course'),
      id: 'course',
      accessor: 'course',
      filterable: true,
      sortable: true,
      Cell: ({ value }) => {
        const course = courses.find((c) => c._id === value);
        return course ? course.name : t('N/A');
      }
    },
    {
      Header: t('Actions'),
      id: 'actions',
      filterable: false,
      sortable: false,
      Cell: ({ original }) => (
        <>
          <Button color="success" size="sm" onClick={() => handleManageStudents(original)}>
            {t('Manage Students')}
          </Button>{' '}
          <Button color="info" size="sm" onClick={() => handleEdit(original)}>
            {t('Edit')}
          </Button>{' '}
          {original.numberOfParticipations === 0 && 
            <Button color="danger" size="sm" onClick={() => handleDelete(original._id)}>
              {t('Delete')}
            </Button>
          }
        </>
      )
    }
  ];

  return (
    <>
      <ComponentCard title={`${t('Groups')} (${filteredData.length??0})`}>
        <Button color="primary" onClick={toggleModal}>
          {t('Add New Group')}
        </Button>
        <ButtonGroup className="float-right" style={{marginLeft:'60%'}}>
          <Button color="primary" onClick={() => filterByYear('all')} active={selectedYear === 'all'}>{t('All')}</Button>
          <Button color="primary" onClick={() => filterByYear('2023')} active={selectedYear === '2023'}>2023</Button>
          <Button color="primary" onClick={() => filterByYear('2024')} active={selectedYear === '2024'}>2024</Button>
          <Button color="primary" onClick={() => filterByYear(new Date().getFullYear().toString())} active={selectedYear === new Date().getFullYear().toString()}>{t('Current Year')}</Button>
        </ButtonGroup>
       <TinkerTable  
        columns={getColumns()}
        data={filteredData}
           defaultFilterMethod={filterCaseInsensitive} 
           />
        

 
      </ComponentCard>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            {formData._id ? t('Edit Group') : t('Add New Group')}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">{t('Name')}</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </FormGroup>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="start">{t('Start Date')}</Label>
                  <DatePicker
                    selected={formData.start}
                    onChange={(date) => handleDateChange(date, 'start')}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="end">{t('End Date')}</Label>
                  <DatePicker
                    selected={formData.end}
                    onChange={(date) => handleDateChange(date, 'end')}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="timestart">{t('Time of start')}</Label>
                  <DatePicker
                    selected={formData.timestart}
                    onChange={(date) => handleDateChange(date, 'timestart')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={t('Time')}
                    dateFormat="HH:mm"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="timeend">{t('Time of end')}</Label>
                  <DatePicker
                    selected={formData.timeend}
                    onChange={(date) => handleDateChange(date, 'timeend')}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={t('Time')}
                    dateFormat="HH:mm"
                    className="form-control"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="course">{t('Course')}</Label>
                  <Input
                    type="select"
                    name="course"
                    id="course"
                    value={formData.course}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t('Select a course')}</option>
                    {courses
                      .filter((course) => course.lcfs.some((lcf) => lcf.$oid === user._id))
                      .map((course) => (
                        <option key={course._id} value={course._id}>
                          {course.name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="teacher">{t('Teacher')}</Label>
                  <Input
                    type="select"
                    name="teacher"
                    id="teacher"
                    value={formData.teacher}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t('Select a teacher')}</option>
                    {teachers.map((teacher) => (
                      <option
                        key={teacher._id}
                        value={teacher._id}
                      >{`${teacher.name} ${teacher.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <FormGroup>
                  <Label for="master">{t('Master Franchisor')}</Label>
                  <Input
                    type="select"
                    name="master"
                    id="master"
                    value={formData.master}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t('Select a master franchisor')}</option>
                    {masters.map((master) => (
                      <option
                        key={master._id}
                        value={master._id}
                      >{`${master.name} ${master.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col span={12}>
                <FormGroup>
                  <Label for="lcf">{t('LCF')}</Label>
                  <Input
                    type="select"
                    name="lcf"
                    id="lcf"
                    value={formData.lcf}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t('Select an LCF')}</option>
                    {lcf.map((l) => (
                      <option key={l._id} value={l._id}>{`${l.name} ${l.lname}`}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="active"
                  checked={formData.active}
                  onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                />{' '}
                {t('Active')}
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {t('Save')}
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              {t('Close')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={studentModal} toggle={toggleStudentModal} size="lg">
        <ModalHeader toggle={toggleStudentModal}>
          {`${t('Manage Students for')} ${selectedEvent?.name}`}
        </ModalHeader>
        <ModalBody>
          <Row style={{border:'3px solid gray'}} className='border'>
            <Col span={8}>
              <Title className='px-2' level={5}>{t('Select Month')} <span style={{float:'right'}} ><ArrowBigDownDash/><ArrowBigUpDash/></span></Title>
              <CalendarEntryContainer ref={monthsContainerRef}>
              {uniqueMonths.map((month) => {
                const isCurrent = month === moment().format('YYYY-MM');
                return (
                  <MonthButton
                    key={month}
                    data-month={month}
                    onClick={() => handleMonthSelect(month)}
                    className={selectedMonth === month ? 'selected' : ''}
                    disabled={isLoading}
                    isCurrent={isCurrent}
                  >
                    {moment(month).format('MMM YYYY')}
                  </MonthButton>
                );
              })}
            </CalendarEntryContainer>
            </Col>

            {selectedMonth && (
              <Col span={16}>
                <Title className='px-2' level={5}>{t('Select Day')}<span style={{float:'right'}} ><CalendarArrowDown/></span></Title>
                <CalendarEntryContainer>
                  {entriesForSelectedMonth.sort((a,b) => new Date(a.start) - new Date(b.start)).map((entry) => (
                    <CalendarEntryButton
                      key={entry._id}
                      onClick={() => handleCalendarEntrySelect(entry)}
                      className={selectedCalendarEntry === entry._id ? 'selected' : ''}
                      disabled={isLoading}
                    >
                      {moment(entry.start).format('DD.MM.YYYY')}
                    </CalendarEntryButton>
                  ))}
                </CalendarEntryContainer>
              </Col>
            )}
          </Row>

          {selectedCalendarEntry && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Card>
                    <Title level={4}>{t('Unassigned Students')}</Title>
                    <Input
                      type="text"
                      placeholder={t('Filter students...')}
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                      style={{ marginBottom: '10px' }}
                      disabled={isLoading}
                    />
                    <CardBody style={{ maxHeight: '450px', overflow: 'scroll', fontSize: 12 }}>
                      <Droppable droppableId="unassigned">
                        {(provided, snapshot) => (
                          <StudentList
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                          >
                            {filteredUnassignedStudents.filter(fus=> fus.lcf === selectedEvent.lcf).map((student, index) => (
                              <Draggable
                                isDragDisabled={isLoading || tempSelectedCalendarEntry?.active === false}
                                key={student._id}
                                draggableId={student._id}
                                index={index}
                              >
                                {(provided1, snapshot1) => (
                                  <StudentItem
                                    ref={provided1.innerRef}
                                    {...provided1.draggableProps}
                                    {...provided1.dragHandleProps}
                                    isDragging={snapshot1.isDragging}
                                  >
                                    {student.name} {student.lname}
                                    {assignedStudents.length < 10 && (
                                     
                                      <Button
                                        color="primary"
                                        size="sm"
                                        style={{ marginLeft: 10, float: 'right' }}
                                        onClick={() =>
                                          handleAddStudentToSubsequentEntries(
                                            selectedCalendarEntry,
                                            student._id,
                                          )
                                        }
                                        disabled={isLoading || tempSelectedCalendarEntry?.active === false}
                                      >
                                        {t('Add')}
                                      </Button>
                                    )}
                                  </StudentItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </StudentList>
                        )}
                      </Droppable>
                    </CardBody>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Title level={4}>{`${t('Assigned Students')} (${assignedStudents.length??0}/10)`}</Title>

                    <CardBody style={{ maxHeight: '450px', overflow: 'scroll', fontSize: 12 }}>
                      <Droppable droppableId="assigned">
                        {(provided, snapshot) => (
                          <StudentList
                            ref={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            {...provided.droppableProps}
                          >
                            {assignedStudents.map((student, index) => (
                              <Draggable
                                isDragDisabled={isLoading}
                                key={student._id}
                                draggableId={student._id}
                                index={index}
                              >
                                {(providedd, snapshott) => (
                                  <StudentItem
                                    ref={providedd.innerRef}
                                    {...providedd.draggableProps}
                                    {...providedd.dragHandleProps}
                                    isDragging={snapshott.isDragging}
                                  >
                                    {student.name} {student.lname}
                                    <Button
                                      color="danger"
                                      size="sm"
                                      style={{ marginLeft: 10, float: 'right' }}
                                      onClick={() =>
                                        handleRemoveStudentFromSubsequentEntries(
                                          selectedCalendarEntry,
                                          student._id,
                                        )
                                      }
                                      disabled={isLoading || tempSelectedCalendarEntry?.active === false}
                                    >
                                      {t('Remove')}
                                    </Button>
                                  </StudentItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </StudentList>
                        )}
                      </Droppable>
                    </CardBody>

                    <Alert color="warning" isOpen={assignedStudents.length >= 10}>
                      {t('Maximum number of students reached!')}
                    </Alert>
                  </Card>
                </Col>
              </Row>
            </DragDropContext>
          )}
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <Card>
                <Title level={4}>{t('Exited Students')}</Title>
                <CardBody style={{ maxHeight: '200px', overflow: 'scroll', fontSize: 12 }}>
                  {softDeletedStudents.map((student) => (
                    <div key={student._id} style={{ marginBottom: '5px' }}>
                      {student.name} {student.lname} -{' '}
                      {moment(student.latest_deleted_calendar.start).format('DD/MM/YYYY')}
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleStudentModal}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={updateModal} backdrop="static" keyboard={false}>
        <ModalBody>
          <div className="text-center">
            <h4>{t('Syncing group...')}</h4>
            <p>{t('Please wait while the group is being synced.')}</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Groups;